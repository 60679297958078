import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "../assets/css/style.css";
import "../assets/css/meanmenu.min.css";
import { gsap } from "gsap";
import "../assets/css/responsive.css";
import Header from "./Header";
import { getMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link } from "react-router-dom";

const HomePage = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [siteData, setsiteData] = useState(null);

  useEffect(() => {
    const handleMouseMove = (event) => {
      setPosition({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };

  }, []);

  const [hoveredCells, setHoveredCells] = useState([]);

  const handleMouseEnter = (targets) => {
    console.log(targets, "target");
    setHoveredCells(targets);
    // var audio = document.getElementsByTagName("audio")[0];
    // audio.play();
  };

  const handleMouseLeave = () => {
    setHoveredCells([]);
  };

  const imageClass = (target) => {
    let className = "ban-image";
    if (hoveredCells.includes(target)) {
      className += " opacity-05";
    } else if (hoveredCells.length > 0) {
      className += " scale-1";
    }
    return className;
  };

  const [activeTab, setActiveTab] = useState("prod-monthly");

  // Function to handle tab change
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const handlebuttonsAudio = () => {
    // var audio = document.getElementsByTagName("audio")[0];
    // audio.play();
  };

  useEffect(() => {
    getsiteData();
  },[0])

  const getsiteData = async () => {
    try {
      var datas = {
        apiUrl: apiService.get_sitedata,
      };
      var response = await getMethod(datas);
      if (response != null) {
        if(response.status)
        {
          setsiteData(response.data)
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <div className="page-wrapper">
      <Header />

      <section className="slider-one">
        <div className="main-slider swiper-slide ">
          <div>
            <div>
              <div
                className="slider-one_icon"
                style={{
                  backgroundImage: `url(${require("../assets/images/main-slider/pattern-2.png")})`,
                }}
              ></div>
              <div
                className="slider-one_icon-two"
                style={{
                  backgroundImage: `url(${require("../assets/images/main-slider/pattern-3.png")})`,
                }}
              ></div>
              <div
                className="slider-one_pattern"
                // style={{ backgroundImage: `url(${require('../assets/images/main-slider/pattern-1.png')})` }}
              ></div>
              <div
                className="slider-one_pattern-two"
                style={{
                  backgroundImage: `url(${require("../assets/images/main-slider/pattern-2.png")})`,
                }}
              ></div>
              <div
                className="slider-one_pattern-four"
                style={{
                  backgroundImage: `url(${require("../assets/images/main-slider/pattern-4.png")})`,
                }}
              ></div>
              <div className="auto-container ">
                <div className="row justify-content-between">
                  <div className=" title  col-lg-6 col-md-12 col-sm-12">
                    <div className="title-inner cafe cafe-inner">
                      <h1 className="slider-one_heading word ">
                        Discover the Power of <span>Financial Freedom </span>
                      </h1>

                      <div className="slider_text">
                        Empower Your Future with Wonderful Xdream Life
                      </div>
                      <div className="slider-one_text">
                        Multiply your earnings, build your network, and unlock
                        limitless opportunities. Start your journey to financial
                        freedom today!
                      </div>
                      <div className=" d-flex align-items-center flex-wrap">
                        <a href="/login" className="template-btn btn-style-one btn_width">
                          <span className="btn-wrap">
                            <span
                              className="text-one"
                              onMouseEnter={() => handlebuttonsAudio()}
                            >
                              Get Started Now
                            </span>
                            <span
                              className="text-two"
                              onMouseEnter={() => handlebuttonsAudio()}
                            >
                              Get Started Now
                            </span>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="slider-one_image-column col-lg-5 col-md-12 col-sm-12">
                    {/* <div
                      className="slider-one_pattern-three"
                      //   style={{ backgroundImage: `url(${require('../assets/images/main-slider/pattern-3.png')})` }}
                    ></div> */}
                    <div className="slider-one_image-outer">
                      <figure className="slider-one_image-box">
                        <img
                          src={require("../assets/images/main-slider/1.png")}
                          width="100%"
                          className={`${imageClass("1")} rotate`}
                          onMouseEnter={() =>
                            handleMouseEnter([
                              "2",
                              "3",
                              "4",
                              "5",
                              "6",
                              "7",
                              "8",
                            ])
                          }
                          onMouseLeave={handleMouseLeave}
                          data-target="1"
                        />
                        <img
                          src={require("../assets/images/main-slider/2.png")}
                          width="100%"
                          className={imageClass("2")}
                          onMouseEnter={() =>
                            handleMouseEnter([
                              "1",
                              "3",
                              "4",
                              "5",
                              "6",
                              "7",
                              "8",
                            ])
                          }
                          onMouseLeave={handleMouseLeave}
                          data-target="2"
                        />
                        <img
                          src={require("../assets/images/main-slider/3.png")}
                          width="100%"
                          className={imageClass("3")}
                          onMouseEnter={() =>
                            handleMouseEnter([
                              "2",
                              "1",
                              "4",
                              "5",
                              "6",
                              "7",
                              "8",
                            ])
                          }
                          onMouseLeave={handleMouseLeave}
                          data-target="3"
                        />
                        <img
                          src={require("../assets/images/main-slider/4.png")}
                          width="100%"
                          className={imageClass("4")}
                          onMouseEnter={() =>
                            handleMouseEnter([
                              "2",
                              "3",
                              "1",
                              "5",
                              "6",
                              "7",
                              "8",
                            ])
                          }
                          onMouseLeave={handleMouseLeave}
                          data-target="3"
                        />
                        <img
                          src={require("../assets/images/main-slider/5.png")}
                          width="100%"
                          className={imageClass("5")}
                          onMouseEnter={() =>
                            handleMouseEnter([
                              "2",
                              "3",
                              "4",
                              "1",
                              "6",
                              "7",
                              "8",
                            ])
                          }
                          onMouseLeave={handleMouseLeave}
                          data-target="5"
                        />
                        <img
                          src={require("../assets/images/main-slider/6.png")}
                          width="100%"
                          className={imageClass("6")}
                          onMouseEnter={() =>
                            handleMouseEnter([
                              "2",
                              "3",
                              "4",
                              "5",
                              "1",
                              "7",
                              "8",
                            ])
                          }
                          onMouseLeave={handleMouseLeave}
                          data-target="6"
                        />
                        <img
                          src={require("../assets/images/main-slider/7.png")}
                          width="100%"
                          className={imageClass("7")}
                          onMouseEnter={() =>
                            handleMouseEnter([
                              "2",
                              "3",
                              "4",
                              "5",
                              "6",
                              "1",
                              "8",
                            ])
                          }
                          onMouseLeave={handleMouseLeave}
                          data-target="7"
                        />
                        <img
                          src={require("../assets/images/main-slider/8.png")}
                          width="100%"
                          className={imageClass("8")}
                          onMouseEnter={() =>
                            handleMouseEnter(["3", "4", "5", "6", "7", "8"])
                          }
                          onMouseLeave={handleMouseLeave}
                          data-target="8"
                        />

                        <audio
                          id="sound"
                          className="d-none"
                          controls
                          preload="auto"
                        >
                          <source
                            src={require("../assets/images/main-slider/videoplayback.mp3")}
                            controls
                          ></source>
                        </audio>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="services-one" id="features">
        <div class="auto-container">
          <div class="sec-title centered">
            <h2 class="sec-title_heading"> Why Choose </h2>
            <div class="sec-title_title">Wonderful Xdream Life?</div>
          </div>
          <div class="row clearfix">
            <div
              class="service-block_one col-lg-4 col-md-6 col-sm-12"
              onMouseEnter={() => handlebuttonsAudio()}
            >
              <div
                class="service-block_one-inner wow fadeInLeft"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div class="service-block_one-icon">
                  {/* <i class="icon-speaker1"></i> */}
                  {/* <img src={require('../assets/images/icons/stake.png')} /> */}
                  <i class="fa-solid fa-layer-group"></i>
                </div>
                <h5 class="service-block_one-heading">
                  <a href="service-detail.html">Advanced Staking Options</a>
                </h5>
                <div class="service-block_one-text">
                  Maximize your earnings with <span>flexible</span> and{" "}
                  <span>high-yield</span> staking opportunities.
                </div>
              </div>
            </div>

            <div
              class="service-block_one col-lg-4 col-md-6 col-sm-12"
              onMouseEnter={() => handlebuttonsAudio()}
            >
              <div
                class="service-block_one-inner wow fadeInLeft"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div class="service-block_one-icon">
                  <i class="fa-solid fa-user-group"></i>
                </div>
                <h5 class="service-block_one-heading">
                  <a href="service-detail.html">Referral Program</a>
                </h5>
                <div class="service-block_one-text">
                  Earn rewards by inviting <span>others to join</span> and grow
                  their network.
                </div>
              </div>
            </div>

            <div
              class="service-block_one col-lg-4 col-md-6 col-sm-12"
              onMouseEnter={() => handlebuttonsAudio()}
            >
              <div
                class="service-block_one-inner wow fadeInLeft"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div class="service-block_one-icon">
                  <i class="fa-solid fa-unlock"></i>
                </div>
                <h5 class="service-block_one-heading">
                  <a href="service-detail.html">
                    Secure & Transparent Transactions
                  </a>
                </h5>
                <div class="service-block_one-text">
                  Benefit from robust security measures and{" "}
                  <span>complete transparency</span> in all your transactions.
                </div>
              </div>
            </div>

            <div
              class="service-block_one col-lg-4 col-md-6 col-sm-12"
              onMouseEnter={() => handlebuttonsAudio()}
            >
              <div
                class="service-block_one-inner wow fadeInLeft"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div class="service-block_one-icon">
                  <i class="fa-solid fa-users"></i>
                </div>
                <h5 class="service-block_one-heading">
                  <a href="service-detail.html">Community Engagement</a>
                </h5>
                <div class="service-block_one-text">
                  Connect with like-minded individuals and participate in{" "}
                  <span>community events</span> and discussions.
                </div>
              </div>
            </div>

            <div
              class="service-block_one col-lg-4 col-md-6 col-sm-12"
              onMouseEnter={() => handlebuttonsAudio()}
            >
              <div
                class="service-block_one-inner wow fadeInLeft"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div class="service-block_one-icon">
                  <i class="fa-solid fa-globe"></i>
                </div>
                <h5 class="service-block_one-heading">
                  <a href="service-detail.html">Global Accessibility</a>
                </h5>
                <div class="service-block_one-text">
                  Access our platform from <span>anywhere</span> in the world,
                  at any time.
                </div>
              </div>
            </div>
            <div
              class="service-block_one col-lg-4 col-md-6 col-sm-12"
              onMouseEnter={() => handlebuttonsAudio()}
            >
              <div
                class="service-block_one-inner wow fadeInLeft"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div class="service-block_one-icon">
                  <i class="fa-solid fa-headset"></i>
                </div>
                <h5 class="service-block_one-heading">
                  <a href="service-detail.html">24/7 Customer Support</a>
                </h5>
                <div class="service-block_one-text">
                  Our dedicated support team is{" "}
                  <span>available around the clock</span> to assist you with any
                  inquiries or issues.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="about"></div>
      </section>

      <section class="about-one" >
        <div
          class="about-one_pattern"
          style={{
            backgroundImage: `url(${require("../assets/images/background/about-pattern.png")})`,
          }}
        ></div>
        {/* <div class="about-one_icon" style={{ backgroundImage: `url(${require('../assets/images/icons/about-1.png')})` }}></div> */}
        {/* <div class="about-one_icon-two" style={{ backgroundImage: `url(${require('../assets/images/icons/about-2.png')})` }}></div> */}
        <div class="auto-container">
          <div class="row clearfix">
            <div class="about-one_tab-column col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div class="about-one_tab-outer">
                <div
                  class="about-one_tab-shadow"
                  style={{
                    backgroundImage: `url(${require("../assets/images/background/tab-shadow.png")})`,
                  }}
                ></div>
                <div class="about-one_tab-image">
                  <img
                    src={require("../assets/images/resource/tabs.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div class="about-one_content-column col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div class="about-one_content-outer" id="about">
                <div class="sec-title">
                  <div class="sec-title_title about_us_title">About us</div>
                  <h2 class="sec-title_heading">
                    Empowering Your Financial Future With Innovation and
                    Expertise Our Journey
                  </h2>
                </div>

                <p>
                  Our mission is to understand the unique needs of our users and
                  deliver top-tier, secure services that empower them to achieve
                  their life goals and dreams. Our global platform is designed
                  to give you greater control over your financial future,
                  ensuring that you can grow your wealth faster and more
                  efficiently than ever before.
                </p>
                <div class="about-one_options d-flex align-items-center flex-wrap">
                  <a href="/login" class="template-btn btn-style-one btn_width">
                    <span
                      class="btn-wrap"
                      onMouseEnter={() => handlebuttonsAudio()}
                    >
                      <span class="text-one">Explore Now</span>
                      <span class="text-two">Explore Now</span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="steps-one" id="how_it_work">
        <div
          class="steps-one_bg"
          style={{
            backgroundImage: `url(${require("../assets/images/background/step-1_bg.png")})`,
          }}
        ></div>
        <div
          class="steps-one_icon"
          style={{
            backgroundImage: `url(${require("../assets/images/icons/step.png")})`,
          }}
        ></div>
        <div class="auto-container">
          <div class="inner-container">
            <div class="circle-layer"></div>
            <div class="dots-layer">
              <span class="dot-one"></span>
              <span class="dot-two"></span>
            </div>

            <div class="sec-title">
              <div class="sec-title_title about_us_title">How it's work</div>
              <h2 class="sec-title_heading">
                WXDL Typically Operate <br />
                In a Three Steps
              </h2>
            </div>
            <div class="steps-one_button">
              <a href="/login" class="template-btn btn-style-two">
                <span
                  class="btn-wrap"
                  onMouseEnter={() => handlebuttonsAudio()}
                >
                  <span class="text-one">Explore Now</span>
                  <span class="text-two">Explore Now</span>
                </span>
              </a>
            </div>
            <div class="row clearfix">
              <div class="column col-lg-6 col-md-12 col-sm-12">
                <div class="step-block_one">
                  <div class="step-block_one-inner">
                    <div class="step-block_one-steps">step 01</div>
                    <h5 class="step-block_one-title">
                      Convert USDT to WXDL Token
                    </h5>
                    <div class="step-block_one-text">
                      Transform your USDT into WXDL tokens with ease. Join
                      Xdreamlife's innovative platform and start your journey
                      towards financial freedom today.
                    </div>
                    <div class="step-block_one-content">
                      <div class="image">
                        <img
                          src={require("../assets/images/resource/step-1.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column col-lg-6 col-md-12 col-sm-12">
                <div class="step-block_one">
                  <div class="step-block_one-inner">
                    <div class="step-block_one-steps">step 02</div>
                    <h5 class="step-block_one-title">
                      Stake and Get Ultimate ROI
                    </h5>
                    <div class="step-block_one-text">
                      Unlock unparalleled returns on your investment with
                      Xdreamlife. Stake your WXDL tokens and watch your wealth
                      grow.
                    </div>
                    <div class="step-block_one-content">
                      <div class="image">
                        <img
                          src={require("../assets/images/resource/step-2.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="step-block_one">
                  <div class="step-block_one-inner">
                    <div class="step-block_one-steps">step 03</div>
                    <h5 class="step-block_one-title">
                      Refer and Earn Ultra Level Rewards
                    </h5>
                    <div class="step-block_one-text">
                      Maximize your earnings with Xdreamlife's referral program.
                      Invite others to join and enjoy exclusive rewards.
                    </div>
                    <div class="step-block_one-content">
                      <div class="image">
                        <img
                          src={require("../assets/images/resource/step-3.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="price-one" id="packages">
        <div
          class="price-one_bg"
          style={{
            backgroundImage: `url(${require("../assets/images/background/price-bg.png")})`,
          }}
        ></div>
        <div class="auto-container top_padding">
          <div class="inner-container   ">
            <div class="sec-title title-anim centered">
              <div class="sec-title_title about_us_title">
                Tailored Investment Solutions
              </div>
              <h2 class="sec-title_heading">
                Join Now to Unlock Exclusive Rewards!
              </h2>
            </div>

            <div className="pricing-tabs tabs-box">
              <div className="buttons-outer">
                <ul className="tab-buttons clearfix">
                  <li
                    data-tab="#prod-monthly"
                    className={`tab-btn ${
                      activeTab === "prod-monthly" ? "active-btn" : ""
                    }`}
                    onClick={() => handleTabClick("prod-monthly")}
                  >
                    Essential
                  </li>
                  <li
                    data-tab="#prod-yearly"
                    className={`tab-btn ${
                      activeTab === "prod-yearly" ? "active-btn" : ""
                    }`}
                    onClick={() => handleTabClick("prod-yearly")}
                  >
                    Premium
                  </li>
                </ul>
              </div>

              <div className="tabs-content">
                {activeTab === "prod-monthly" && (
                  <div className="tab active-tab" id="prod-monthly">
                    <div className="content">
                      <div className="row justify-content-center clearfix">
                        <div class="price-block_one col-lg-4 col-md-6 col-sm-12">
                          <div class="price-block_one-inner">
                            <div class="price-block_one-title">
                              Starter Package
                            </div>
                            <div class="price-block_one-content">
                              <div class="d-flex justify-content-between align-items-end flex-wrap">
                                {/* <div class="price-block_one-price">
                                  <sup></sup>50<sub>WXDL</sub>
                                </div> */}
                                <div class="price-block_one-price">
                                  <sup></sup>$ 50
                                </div>
                              </div>
                              <div
                                class="price-block_one-button"
                                onMouseEnter={() => handlebuttonsAudio()}
                              >
                                <Link
                                  class="template-btn price-one_button"
                                  to="/staking"
                                >
                                  Hold USDT
                                </Link>
                              </div>
                              <ul class="price-block_one-list">
                                <li>
                                  <i class="fa-solid fa-check fa-fw"></i>Daily
                                  ROI {siteData && siteData.essential_roi}%
                                </li>
                                <li>
                                  <i class="fa-solid fa-check fa-fw"></i>Profit
                                  Cap 3X
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div class="price-block_one col-lg-4 col-md-6 col-sm-12">
                          <div class="price-block_one-inner">
                            <div class="price-block_one-title">
                              Basic Package
                            </div>
                            <div class="price-block_one-content">
                              <div class="d-flex justify-content-between align-items-end flex-wrap">
                                {/* <div class="price-block_one-price">
                                  <sup></sup>100<sub>WXDL</sub>
                                </div> */}
                                <div class="price-block_one-price">
                                  <sup></sup>$ 100
                                </div>
                              </div>
                              <div
                                class="price-block_one-button"
                                onMouseEnter={() => handlebuttonsAudio()}
                              >
                                 <Link
                                  class="template-btn price-one_button"
                                  to="/staking"
                                >
                                  Hold USDT
                                </Link>
                              </div>
                              <ul class="price-block_one-list">
                                <li>
                                  <i class="fa-solid fa-check fa-fw"></i>Daily
                                  ROI {siteData && siteData.essential_roi}%
                                </li>
                                <li>
                                  <i class="fa-solid fa-check fa-fw"></i>Profit
                                  Cap 3X
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div class="price-block_one col-lg-4 col-md-6 col-sm-12">
                          <div class="price-block_one-inner">
                            <div class="price-block_one-title">
                              Advanced Package
                            </div>
                            <div class="price-block_one-content">
                              <div class="d-flex justify-content-between align-items-end flex-wrap">
                                {/* <div class="price-block_one-price">
                                  <sup></sup>200<sub>WXDL</sub>
                                </div> */}
                                <div class="price-block_one-price">
                                  <sup></sup>$ 200
                                </div>
                              </div>
                              <div
                                class="price-block_one-button"
                                onMouseEnter={() => handlebuttonsAudio()}
                              >
                                 <Link
                                  class="template-btn price-one_button"
                                  to="/staking"
                                > 
                                  Hold USDT
                                </Link>
                              </div>
                              <ul class="price-block_one-list">
                                <li>
                                  <i class="fa-solid fa-check fa-fw"></i>Daily
                                  ROI {siteData && siteData.essential_roi}%
                                </li>
                                <li>
                                  <i class="fa-solid fa-check fa-fw"></i>Profit
                                  Cap 3X
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div class="price-block_one col-lg-4 col-md-6 col-sm-12">
                          <div class="price-block_one-inner">
                            <div class="price-block_one-title">Pro Package</div>
                            <div class="price-block_one-content">
                              <div class="d-flex justify-content-between align-items-end flex-wrap">
                                {/* <div class="price-block_one-price">
                                  <sup></sup>300<sub>WXDL</sub>
                                </div> */}
                                 <div class="price-block_one-price">
                                  <sup></sup>$ 300
                                </div>
                              </div>
                              <div
                                class="price-block_one-button"
                                onMouseEnter={() => handlebuttonsAudio()}
                              >
                                 <Link
                                  class="template-btn price-one_button"
                                  to="/staking"
                                >
                                  Hold USDT
                                </Link>
                              </div>
                              <ul class="price-block_one-list">
                                <li>
                                  <i class="fa-solid fa-check fa-fw"></i>Daily
                                  ROI {siteData && siteData.essential_roi}%
                                </li>
                                <li>
                                  <i class="fa-solid fa-check fa-fw"></i>Profit
                                  Cap 3X
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div class="price-block_one col-lg-4 col-md-6 col-sm-12">
                          <div class="price-block_one-inner">
                            <div class="price-block_one-title">
                              Elite Package
                            </div>
                            <div class="price-block_one-content">
                              <div class="d-flex justify-content-between align-items-end flex-wrap">
                                {/* <div class="price-block_one-price">
                                  <sup></sup>500<sub>WXDL</sub>
                                </div> */}
                                 <div class="price-block_one-price">
                                  <sup></sup>$ 500
                                </div>
                              </div>
                              <div
                                class="price-block_one-button"
                                onMouseEnter={() => handlebuttonsAudio()}
                              >
                                <Link
                                  class="template-btn price-one_button"
                                  to="/staking"
                                >
                                  Hold USDT
                                </Link>
                              </div>
                              <ul class="price-block_one-list">
                                <li>
                                  <i class="fa-solid fa-check fa-fw"></i>Daily
                                  ROI {siteData && siteData.essential_roi}%
                                </li>
                                <li>
                                  <i class="fa-solid fa-check fa-fw"></i>Profit
                                  Cap 3X
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "prod-yearly" && (
                  <div className="tab active-tab" id="prod-yearly">
                    <div className="content">
                      <div className="row justify-content-center clearfix">
                        <div class="price-block_one col-lg-4 col-md-6 col-sm-12">
                          <div class="price-block_one-inner">
                            <div class="price-block_one-title">
                              Bronze Package
                            </div>
                            <div class="price-block_one-content">
                              <div class="d-flex justify-content-between align-items-end flex-wrap">
                                {/* <div class="price-block_one-price">
                                  <sup></sup>1000<sub>WXDL</sub>
                                </div> */}
                                 <div class="price-block_one-price">
                                  <sup></sup>$ 1000
                                </div>
                              </div>
                              <div
                                class="price-block_one-button"
                                onMouseEnter={() => handlebuttonsAudio()}
                              >
                                  <Link
                                  class="template-btn price-one_button"
                                  to="/staking"
                                >
                                  Hold USDT
                                </Link>
                              </div>
                              <ul class="price-block_one-list">
                                <li>
                                  <i class="fa-solid fa-check fa-fw"></i>Daily
                                  ROI {siteData && siteData.premium_roi}%
                                </li>
                                <li>
                                  <i class="fa-solid fa-check fa-fw"></i>Profit
                                  Cap 3X
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div class="price-block_one col-lg-4 col-md-6 col-sm-12">
                          <div class="price-block_one-inner">
                            <div class="price-block_one-title">
                              Silver Package
                            </div>
                            <div class="price-block_one-content">
                              <div class="d-flex justify-content-between align-items-end flex-wrap">
                                {/* <div class="price-block_one-price">
                                  <sup></sup>2000<sub>WXDL</sub>
                                </div> */}
                                <div class="price-block_one-price">
                                  <sup></sup>$ 2000
                                </div>
                              </div>
                              <div
                                class="price-block_one-button"
                                onMouseEnter={() => handlebuttonsAudio()}
                              >
                                  <Link
                                  class="template-btn price-one_button"
                                  to="/staking"
                                >
                                  Hold USDT
                                </Link>
                              </div>
                              <ul class="price-block_one-list">
                                <li>
                                  <i class="fa-solid fa-check fa-fw"></i>Daily
                                  ROI {siteData && siteData.premium_roi}%
                                </li>
                                <li>
                                  <i class="fa-solid fa-check fa-fw"></i>Profit
                                  Cap 3X
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div class="price-block_one col-lg-4 col-md-6 col-sm-12">
                          <div class="price-block_one-inner">
                            <div class="price-block_one-title">
                              Gold Package
                            </div>
                            <div class="price-block_one-content">
                              <div class="d-flex justify-content-between align-items-end flex-wrap">
                                {/* <div class="price-block_one-price">
                                  <sup></sup>5000<sub>WXDL</sub>
                                </div> */}
                                <div class="price-block_one-price">
                                  <sup></sup>$ 5000
                                </div>
                              </div>
                              <div
                                class="price-block_one-button"
                                onMouseEnter={() => handlebuttonsAudio()}
                              >
                                  <Link
                                  class="template-btn price-one_button"
                                  to="/staking"
                                >
                                  Hold USDT
                                </Link>
                              </div>
                              <ul class="price-block_one-list">
                                <li>
                                  <i class="fa-solid fa-check fa-fw"></i>Daily
                                  ROI {siteData && siteData.premium_roi}%
                                </li>
                                <li>
                                  <i class="fa-solid fa-check fa-fw"></i>Profit
                                  Cap 3X
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div class="price-block_one col-lg-4 col-md-6 col-sm-12">
                          <div class="price-block_one-inner">
                            <div class="price-block_one-title">
                              Platinum Package
                            </div>
                            <div class="price-block_one-content">
                              <div class="d-flex justify-content-between align-items-end flex-wrap">
                                {/* <div class="price-block_one-price">
                                  <sup></sup>8000<sub>WXDL</sub>
                                </div> */}
                                <div class="price-block_one-price">
                                  <sup></sup>$ 8000
                                </div>
                              </div>
                              <div
                                class="price-block_one-button"
                                onMouseEnter={() => handlebuttonsAudio()}
                              >
                                  <Link
                                  class="template-btn price-one_button"
                                  to="/staking"
                                >
                                  Hold USDT
                                </Link>
                              </div>
                              <ul class="price-block_one-list">
                                <li>
                                  <i class="fa-solid fa-check fa-fw"></i>Daily
                                  ROI {siteData && siteData.premium_roi}%
                                </li>
                                <li>
                                  <i class="fa-solid fa-check fa-fw"></i>Profit
                                  Cap 3X
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div class="price-block_one col-lg-4 col-md-6 col-sm-12">
                          <div class="price-block_one-inner">
                            <div class="price-block_one-title">
                              Diamond Package
                            </div>
                            <div class="price-block_one-content">
                              <div class="d-flex justify-content-between align-items-end flex-wrap">
                                {/* <div class="price-block_one-price">
                                  <sup></sup>10000<sub>WXDL</sub>
                                </div> */}
                                <div class="price-block_one-price">
                                  <sup></sup>$ 10000
                                </div>
                              </div>
                              <div
                                class="price-block_one-button"
                                onMouseEnter={() => handlebuttonsAudio()}
                              >
                                <Link
                                  class="template-btn price-one_button"
                                  to="/staking"
                                >
                                  Hold USDT
                                </Link>
                              </div>
                              <ul class="price-block_one-list">
                                <li>
                                  <i class="fa-solid fa-check fa-fw"></i>Daily
                                  ROI {siteData && siteData.premium_roi}%
                                </li>
                                <li>
                                  <i class="fa-solid fa-check fa-fw"></i>Profit
                                  Cap 3X
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* Add more packages as needed */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
          class="price-two_bg"
          style={{
            backgroundImage: `url(${require("../assets/images/background/effect.png")})`,
          }}
        ></div>
      <section class="cta-one top_padding">
        <div class="auto-container">
          <div class="inner-container">
            {/* <div
              class="cta-icon_one"
              style={{
                backgroundImage: `url(${require("../assets/images/icons/cta-icon-1.png")})`,
              }}
            ></div>
            <div
              class="cta-icon_two"
              style={{
                backgroundImage: `url(${require("../assets/images/icons/cta-icon-2.png")})`,
              }}
            ></div>
            <div
              class="cta-icon_two"
              style={{
                backgroundImage: `url(${require("../assets/images/icons/circle.png")})`,
              }}
            ></div> */}

            <div class="cta-one_card" id="community">
              <img src="assets/images/icons/cta-card.png" alt="" />
            </div>
            <div class="row clearfix ">
              <div class="cta-one_title-column col-lg-6 col-md-12 col-sm-12">
                <div class="cta-one_title-outer">
                  <h2 class="cta-one_title mb-3">Join Our Community</h2>
                  <h4 className="cta-one_h4 mb-3">
                    Stay Informed with Live Updates!
                  </h4>
                  <div class="cta-one_button mb-5">
                    <a href="/login" class="template-btn btn-style-three">
                      <span
                        class="btn-wrap"
                        onMouseEnter={() => handlebuttonsAudio()}
                      >
                        <span class="text-one">Explore Now</span>
                        <span class="text-two">Explore Now</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="cta-one_image-column col-lg-6 col-md-12 col-sm-12">
                <div class="cta-one_image-outer">
                  <div class="cta-one_cards">
                    <img
                      src={require("../assets/images/icons/youtube.png")}
                      alt=""
                    />
                  </div>
                  <div class="cta-one_cards">
                    <img
                      src={require("../assets/images/icons/Telegram.png")}
                      alt=""
                    />
                  </div>
                  <div class="cta-one_cards">
                    <img src={require("../assets/images/icons/X.png")} alt="" />
                  </div>
                  <div class="cta-one_cards">
                    <img
                      src={require("../assets/images/icons/Insta.png")}
                      alt=""
                    />
                  </div>
                </div>
                {/* <div class="image">
								<img src={require("../assets/images/icons/circle.png")} alt="" />
							</div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer class="main-footer">
        <div
          class="footer_pattern"
          style={{
            backgroundImage: `url(${require("../assets/images/background/footer-pattern.png")})`,
          }}
        ></div>
        <div class="auto-container">
          <div class="inner-container">
            <div class="widgets-section">
              <div class="row clearfix">
                <div class="big-column col-lg-5 col-md-12 col-sm-12">
                  <h5 class="footer-title">Xdreamlife</h5>
                  <div class="footer-newsletter_text">
                    At Xdreamlife, we are committed to empowering individuals to
                    become successful entrepreneurs. Our vision extends beyond
                    financial growth, encompassing innovative projects in
                    staking and
                    <br /> e-commerce that foster collective income generation.
                  </div>
                </div>

                <div class="big-column col-lg-7 col-md-12 col-sm-12">
                  <div class="footer-lists_outer">
                    <div class="row clearfix">
                      <div class="column col-lg-5 col-md-4 col-sm-6">
                        <h5 class="footer-title">About Us</h5>
                        <ul class="footer-pages_list">
                          <li>
                            <a href="#">Privacy policy</a>
                          </li>
                          <li>
                            <a href="#">Terms and condition</a>
                          </li>
                        </ul>
                      </div>
                      <div class="column col-lg-3 col-md-4 col-sm-6">
                        <h5 class="footer-title">Resources</h5>
                        <ul class="footer-pages_list">
                          <li>
                            <a href="#">Help center</a>
                          </li>
                          <li>
                            <a href="#">Support</a>
                          </li>
                        </ul>
                      </div>
                      <div class="column col-lg-4 col-md-4 col-sm-6">
                        <h5 class="footer-title">Quick Links</h5>
                        <ul class="footer-pages_list">
                          <li>
                            <a href="#">Home</a>
                          </li>
                          <li>
                            <a href="#about">About</a>
                          </li>
                          <li>
                            <a href="#features">Features</a>
                          </li>
                          <li>
                            <a href="#packages">Packages</a>
                          </li>
                          <li>
                            <a href="#how_it_work">How it works</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bottom">
          <div class="auto-container">
            <div class="inner-container d-flex justify-content-between align-items-center flex-wrap">
              <div class="footer-logo">
                <a href="/">
                  <img
                    src={require("../assets/images/Logo.png")}
                    alt=""
                    title=""
                  />
                </a>
              </div>
              <div class="footer-copyright">
                &copy; 2024 <a href="/">WXDL.</a> All rights reserved.
              </div>
              <div class="footer-social_box">
                <a href="https://facebook.com/">
                  <i class="fa-brands fa-facebook-f"></i>
                </a>
                <a href="https://twitter.com/">
                  <i class="fa-brands fa-twitter"></i>
                </a>
                <a href="https://youtube.com/">
                  <i class="fa-brands fa-youtube"></i>
                </a>
                <a href="https://instagram.com/">
                  <i class="fa-brands fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
