import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuthToken } from "../core/lib/localStorage";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import Pagination from "@mui/material/Pagination";
import TreeNode from "./TreeNode";
import InnerHeader from "./Header";

function Home() {
  const navigate = useNavigate();
  const [referral, setReferral] = useState([]);
  const [referral1, setreferral1] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // New state for search input
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [Back, setBack] = useState(false);
  const [Currentuser, setCurrentuser] = useState("");

  const pageSize = 5;

  const [myDatas, setMyDatas] = useState("");

  useEffect(() => {
    let checkAuth = getAuthToken();
    if (checkAuth) {
      getTree();
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    getReferralUser();
  }, [currentPage]);

  const getReferralUser = async () => {
    var datas = {
      apiUrl: apiService.getReferraluser,
      payload: {
        currentPage: currentPage + 1, // ReactPaginate uses 0-based index, so we add 1
        pageSize: pageSize,
      },
    };
    var response = await postMethod(datas);
    if (response.status) {
      setreferral1(response.data);
      setTotalPages(response.totalPages);
    } else {
      setreferral1([]);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value - 1);
  };

  const getTree = async () => {
    try {
      const data = {
        apiUrl: apiService.treeDetails,
      };
      const resp = await getMethod(data);
      if (resp) {
        const responseData = resp.data;
        setReferral(responseData);

const leftSideStrength = countUsers(responseData.leftUser);
const rightSideStrength = countUsers(responseData.rightUser);
console.log('Left side strength:', leftSideStrength);
console.log('Right side strength:', rightSideStrength);
        setMyDatas(resp.myData);
        setBack(false);
        setSearchTerm("");
      }
    } catch (error) {
      console.error("Error fetching tree data", error);
    }
  };


  const getTree_user = async (userId) => {
    try {
      console.log("tree userId====",userId);
      const data = {
        apiUrl: apiService.treeDetails_view,
        payload : { userId: userId }
      };
      const resp = await postMethod(data);
      if (resp) {
        const responseData = resp.data;
        setReferral(responseData);

const leftSideStrength = countUsers(responseData.leftUser);
const rightSideStrength = countUsers(responseData.rightUser);
console.log('Left side strength:', leftSideStrength);
console.log('Right side strength:', rightSideStrength);
        setMyDatas(resp.myData);
        setBack(false);
        setSearchTerm("");
      }
    } catch (error) {
      console.error("Error fetching tree data", error);
    }
  };

  function countUsers(node) {
    if (node === null) {
        return 0;
    }

    // Count the current node, then recursively count the left and right children
    return 1 + countUsers(node.leftUser) + countUsers(node.rightUser);
}

  const getUserTree = async (datas) => {
    try {
      const data = {
        apiUrl: apiService.usertreeDetails,
        payload: {
          id: datas,
        },
      };
      const resp = await postMethod(data);
      if (resp.status) {
        const responseData = resp.data;
        setReferral(responseData);
        setMyDatas(resp.myData);
      }
    } catch (error) {
      console.error("Error fetching tree data", error);
    }
  };

  const handleNodeClick = (userId, event) => {
    event.stopPropagation(); // Prevent the click event from bubbling up
    console.log(userId);
    getUserTree(userId);
    setBack(true);
    setCurrentuser(userId);
  };
  const handleSearch = async () => {
    if (searchTerm.trim() === "") {
      getTree();
    } else {
      getUserTree(searchTerm);
    }
    setBack(true);
  };

  const convertUTCToIST = (utcDateString) => {
    const options = {
        timeZone: 'Asia/Kolkata',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      };
    
      const istDate = new Date(utcDateString).toLocaleString('en-IN', options);
    
      return istDate;
  };

  


  return (
    <div>
      <div className="inner_header">
        <InnerHeader />
      </div>
      <main className="over-hinden tree_view_main">
        <section className="staking_banner_section binary_tree_section">
          <div className="container">
            <div className="staking_banner_content d-flex align-items-center justify-content-between">
              <h1>Binary Tree</h1>
              <div>
                <input
                  className=""
                  placeholder="Search UserId"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button className="deposit_button1" onClick={handleSearch}>Search</button>
              </div>

            </div>
          </div>
        </section>
        <div className="container">
          <section className="dashborade_content">
            {Back ? (
              // <button className="deposit_button" onClick={() => getTree_user(Currentuser)}>
              <button className="deposit_button" onClick={getTree}>
                Back
              </button>
            ) : (
              ""
            )}

            <div className="col-lg-12">
              <div className="tree-vieww">
                <div className="tree">
                  {referral && (
                    <ul className="tree-container">
                      <TreeNode
                        nodeData={referral}
                        onNodeClick={handleNodeClick}
                        depth={0}
                      />
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </section>
          <div className="container my-5">
            <div className="dashboard_table_content">
              <h3 className="referral_title">Referral History</h3>
              <div className="table_overflow">
                <table className="wxdl_dashboard table">
                  <thead>
                    <tr>
                      <th scope="col" className="start">
                        SI.No
                      </th>
                      <th scope="col">User ID</th>
                      <th scope="col">Email</th>
                      <th scope="col">Name</th>
                      <th scope="col">Mobile Number</th>
                      <th scope="col">Position</th>
                      <th scope="col">Package</th>
                      <th scope="col" className="end">
                        Date & Time
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {referral1 && referral1.length > 0 ? (
                      referral1.map((item, i) => {
                        var dates = convertUTCToIST(item.createdDate);
                        return (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{item.internalID}</td>
                            <td>{item.email}</td>
                            <td>{item.username}</td>
                            <td>{item.mobileNumber}</td>
                            <td>
                              {item.position === "Right" ? "B Team" : "A Team"}
                            </td>
                            <td>
                              {item.plan_amount == 0 ? "No Package" : "$ "+item.plan_amount }
                            </td>
                            <td>{dates}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="7"> No Data found! </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <Pagination
                  count={totalPages}
                  shape="rounded"
                  className="table_pagination"
                  onChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;
