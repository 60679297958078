import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { getMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";

// Register the required components
ChartJS.register(ArcElement, Tooltip, Legend);



const Chart = () => {

  const [siteLoader, setsiteLoader] = useState(false);
  const [chartData, setchartData] = useState("");


  const data = {
    labels: ['23%', '277%'],
    datasets: [
      {
        data: [23, 277],
        backgroundColor: ['#6be7d8', '#a64ac9'], // Colors matching your chart
        hoverBackgroundColor: ['#55d4c3', '#9338b8'], // Optional hover effect colors
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false, // Hide legend if needed
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.label}: ${context.raw}%`, // Customize tooltip
        },
      },
    },
    responsive: true,
  };

    useEffect(() => {
      getDashboardDetails();
    }, [0]);

  const getDashboardDetails = async () => {
    try {
      var datas = {
        apiUrl: apiService.dashboard,
      };
      var response = await getMethod(datas);
      setsiteLoader(true);
      if (response.status) {
        setsiteLoader(false);
        if (response.data != null) {
          let total_earnings = +response.data.stakingAmount * 3;
          let earnings_percentage = (+response.data.total_assets / +total_earnings) * 100;
          let remaining_amount = (+response.data.stakingAmount * 3) - +response.data.all_income;
          let remaining_percentage = (+remaining_amount / +total_earnings) * 100;

          earnings_percentage = parseFloat(earnings_percentage).toFixed(2);
          remaining_percentage = parseFloat(remaining_percentage).toFixed(2);

          const chart_data = {
            labels: ["Total Earnings",  "Balance"],
            datasets: [
              {
                data: [earnings_percentage, remaining_percentage],
                backgroundColor: ['#6be7d8', '#a64ac9'], // Colors matching your chart
                hoverBackgroundColor: ['#55d4c3', '#9338b8'], // Optional hover effect colors
              },
            ],
          };

          console.log("chart_data---",chart_data);

          setchartData(chart_data);
        }
      } else {
      }

    } catch (error) {
    }

  };

  return (
    siteLoader === true ? (
        ""
    ) : 
    ( chartData != "" ?
    <div style={{ width: '180px', height: '180px' }}> {/* Adjust dimensions */}
      <Pie data={chartData} options={options} width={180} height={180} />
    </div> : "")
  );
};

export default Chart;
